import * as React from "react"
import classNames from "classnames"

const MgcRadioToggleButtonV2 = ({
                                field: { name, value, onChange, onBlur },
                                id,
                                label,
                                className,
                                hidden,
                                disabled,
                                onChangeWithReset,
                                ...props
                              }) => {
  const getNestedObject = (nestedObj, pathArr) => {
    return pathArr.reduce((obj, key) =>
      (obj && obj[key] !== 'undefined') ? obj[key] : undefined, nestedObj);
  }
  let val = getNestedObject(props.form.values, name.split('.'))
  val = val === null || val === undefined ? '' : val

  const classes = classNames(
    "btn btn-outline btn-toggle toggle-label",
    {
      "active": id === value || val.toString() === id.toString()
    },
    className,
    {
      "disabled" : disabled
    }
  );
  return (
    <label className={classes} hidden={hidden}>
      <input
        name={name}
        id={id}
        type="radio"
        value={id} // could be something else for output?
        checked={id === value || val.toString() === id.toString()}
        onChange={(e) => {
          if (onChangeWithReset) {
            onChangeWithReset(e)
          } else {
            onChange(e)
          }
        }}
        onBlur={onBlur}
        disabled={disabled}
      />
      {label}
    </label>)
}


export default MgcRadioToggleButtonV2
