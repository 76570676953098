import * as React from "react"
import classNames from "classnames"
import "./mgcRadioToggleButtonGroupV2.css"

const MgcRadioToggleButtonGroupV2 = ({
  error,
  id,
  label,
  children,
  hidden,
  withoutOffset,
}) => {
  const labelClassNames = classNames("", {
    "col-11 offset-1": !withoutOffset,
    "col-12": withoutOffset,
  })

  const formGroupClassNames = classNames("form-group", {
    "col-10 offset-1": !withoutOffset,
    "col-11": withoutOffset,
    "is-invalid": error,
  })
  const checkerClassNames = classNames("fa fa-2x", {
    "fa-times text-danger": error,
    // "fa-check text-success": !error,
  })
  const invalidFeedbackClassNames = classNames("invalid-feedback col-12 pl-0", {
    "d-block": error,
  })
  return (
    <>
      <div className="form-row" hidden={!label || hidden} id={id}>
        <div className={labelClassNames}>
          <label>{label}</label>
        </div>
      </div>
      <div className="form-row" hidden={hidden}>
        <div className={formGroupClassNames}>
          <div
            className="btn-group btn-group-justified"
            data-toggle={"buttons"}
          >
            {children}
          </div>
          <div className={invalidFeedbackClassNames}>{error}</div>
        </div>
        <div className="col-1">
          <span className={checkerClassNames} />
        </div>
      </div>
    </>
  )
}

export default MgcRadioToggleButtonGroupV2
