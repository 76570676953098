import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"
import informationIcon from "../../images/icon-information.png"
import { Field } from "formik"

const MgcTextFieldV2 = ({
  name,
  label,
  placeholder,
  errors,
  hidden,
  tooltip,
  prefix,
  withoutOffset,
  type,
  disabled,
  maxLength,
  onChange,
  isErrorSubmitting,
  onBlur,
  colx
}) => {
  const fieldName = prefix ? `${prefix}${name}` : name
  const error = errors[name]

  const formGroupClassNames = classNames("form-group", {
    "col-9 offset-1": colx===9 && !withoutOffset,
    "col-10 offset-1": !tooltip && !withoutOffset && !colx,
    "col-11": withoutOffset && !tooltip && !colx,
    "col-10": tooltip && !colx,
  })
  const checkerClassNames = classNames("fa fa-2x", {
    "fa-times text-danger": error,
    // "fa-check text-success": !error,
  })

  const fieldClassNames = classNames("form-control mb-0", {
    "is-invalid": error,
  })

  return (
    <div className="form-row" hidden={hidden}>
      {tooltip && (
        <div className="col-1 text-right my-auto">
          <OverlayTrigger
            overlay={
              <Tooltip id="title-tooltip" placement={"right"}>
                <p>{tooltip}</p>
              </Tooltip>
            }
          >
            <img src={informationIcon} className="icon-info-ael" />
          </OverlayTrigger>
        </div>
      )}
      <div className={formGroupClassNames}>
        {!!label && <label>{label}</label>}

        <Field
          type={type ? type : "text"}
          name={fieldName}
          className={fieldClassNames}
          placeholder={placeholder}
          disabled={disabled}
          maxLength={maxLength}
          onChange={onChange}
          id={fieldName}
          onBlur={e => {
            if (onBlur) {
              onBlur(e)
            }
          }}
        />
        <div className="invalid-feedback">{error}</div>
      </div>
      <div className={label ? "col-1  mt-30px" : "col-1"}>
        <span className={checkerClassNames} />
      </div>
    </div>
  )
}

MgcTextFieldV2.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  errors: PropTypes.object,
  dirty: PropTypes.bool,
  hidden: PropTypes.bool,
  tooltip: PropTypes.string,
  prefix: PropTypes.string,
  withoutOffset: PropTypes.bool,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
}

MgcTextFieldV2.defaultProps = {
  type: "text",
  maxLength: 524288,
  onChange: () => {
    console.log("onchage undefined")
  },
}

export default MgcTextFieldV2
