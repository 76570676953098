export const scrollToFirstError = (errors) => {
  if (typeof window !== "undefined" && !!errors) {
    const errorId = findErrorId(errors)
    let elements = findErrorElements(errorId)
    if (!!elements && !!elements[0]) {
      const element = elements[0]
      console.log("parent", element.parentElement)
      element.parentElement.scrollIntoView()
    }
  }
}
/**
 * Construit la chaine correspondant au champ en erreur a partir de l'objet errors
 * @param errors l'objets des erreur
 * @returns {string} le nom du champ erreur
 */
const findErrorId = errors => {
  let errorId = Object.keys(errors)[0]
  if (typeof errors[errorId] === "object") {
    let errorObj = errors[errorId]
    if (Array.isArray(errorObj)) {
      const errorsIndex = errorObj.findIndex(e => {
        return !!e && typeof e === "object"
      })
      errorObj = errorObj[errorsIndex]
      errorId = `${errorId}[${errorsIndex}]`
    }
    const suffix = Object.keys(errorObj)[0]
    errorId = `${errorId}.${suffix}`
  }

  return errorId
}

const findErrorElements = (errorId) => {
  let elements = document.querySelectorAll(`input[name$="${errorId}"]`)
  if (!elements || !elements[0]) {
    elements = [document.getElementById(errorId)]
  }
  return elements
}
